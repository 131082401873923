<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 申请记录
 * @Date: 2020-11-17 20:52:10
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-11-18 19:22:32
 * @FilePath: /yue_quanzhan_h5_new/src/views/localLife/creditCard/ApplicationRecord.vue
-->
<template>
  <div class="content">
    <!--    <van-search-->
    <!--      v-model="value"-->
    <!--      show-action-->
    <!--      placeholder="请输入搜索手机号"-->
    <!--      background="#f8f8f8"-->
    <!--      @search="onSearch"-->
    <!--      @cancel="onSearch"-->
    <!--      @input="onInput"-->
    <!--    >-->
    <!--      <template #action>-->
    <!--        <div @click="onSearch">搜索</div>-->
    <!--      </template>-->
    <!--    </van-search>-->
    <van-tabs v-model="active" color="#ee0a24" @click="ordersChange">
      <div class="tips">
        <p>
          <span>
            *声明：本订单信息仅代表用户发起过申请，不代表完成了申请流程
          </span>
        </p>
      </div>
      <van-tab v-for="(tInfo,tIndex) in titleList" :key="tIndex" :title="tInfo.title">
        <div v-if="isOrder" class="NoData">
          <img
            class="NoDataImg"
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-22/23/yuelvhui680UxFT6uf1608649312.png"
            alt=""
          >
          <p class="NoDataText">您还没有相关订单</p>
        </div>
        <ul v-else class="Cards">
          <li v-for="(item,index) in cardOrderList" :key="index" class="cardsitem">
            <van-row>
              <van-col :span="8" class="item_title">工商银行</van-col>
              <van-col :span="16" class="item_odd">
                <span class="tag-read" :data-clipboard-text="Transactions">交易订单：{{ Transactions }}</span>
                <p><span @click="copy()">复制</span></p>
              </van-col>
            </van-row>
            <van-row class="customer">
              <van-col :span="14">客户姓名:{{ item.truename_identify }}</van-col>
              <van-col :span="10">
                <span v-if="item.order_status == 0">已下单</span>
                <span v-if="item.order_status == 1">已完成</span>
                <span v-if="item.order_status == 2">已拒绝</span>
                <span v-if="item.order_status == 3">无效单</span>
              </van-col>
            </van-row>
            <div class="cards_it">手机号码：{{ item.m_mobile }}</div>
            <div class="cards_it">申请时间：{{ item.order_modify_at }}</div>
          </li>
        </ul>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Vue from 'vue'
import { Col, Row, Search, Tab, Tabs, Toast } from 'vant'

Vue.use(Search).use(Tab).use(Tabs).use(Col).use(Row).use(Toast)
import {
  getCardOrderList
} from '@/services/comeOn.js'
import { copyText } from '@/utils/common/utils'

export default {
  data() {
    return {
      titleList: [
        {
          title: '全部',
          id: 0
        }, {
          title: '已下单',
          id: 1
        }, {
          title: '已完成',
          id: 2
        }, {
          title: '已拒绝',
          id: 3
        }, {
          title: '无效单',
          id: 4
        }],
      value: '',
      active: 0,
      Transactions: 'CR20201106212',
      cardOrderList: [],
      sourceSign: '2095CE15', // 三方编码
      isOrder: true,
      mobile: ''
    }
  },
  created() {
    // 渠道编码
    this.sourceSign = this.getQueryVariable('sourceSign') ? this.getQueryVariable('sourceSign') : '2095CE15'
  },
  mounted() {
    this.mobile = window.localStorage.getItem('mobile') || JSON.parse(localStorage.getItem('userInfo')).mobile
    this.getOrderList(9, this.mobile)
  },
  methods: {
    // url数据
    getQueryVariable(variable) {
      const query = window.location.search.substring(1)
      const vars = query.split('&')
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        if (pair[0] === variable) {
          return pair[1]
        }
      }
      return false
    },
    // get订单
    getOrderList(status, mobileValue) {
      const that = this
      const getCardOrderListData = {
        mid: window.localStorage.getItem('uid'),
        page: 1,
        pageSize: 10000,
        status: status, // 9全部0已下单1已完成2已拒绝3无效单
        sourceSign: that.sourceSign, // 渠道编码
        thirdSign: '', // 三方编码
        mobile: mobileValue// 手机号
      }
      getCardOrderList(getCardOrderListData).then(res => {
        if (Number(res.code) === 200) {
          if (res.data.length === 0) {
            that.isOrder = true
          } else {
            that.isOrder = false
            that.cardOrderList = res.data
          }
        } else {
          Toast(res.msg)
        }
      })
    },
    // 状态点击切换滑动
    ordersChange(item) {
      const that = this
      if (item === 0) { // 全部
        that.getOrderList(9, this.mobile)
      } else if (item === 1) { // 已下单
        that.getOrderList(0, this.mobile)
      } else if (item === 2) { // 已完成
        that.getOrderList(1, this.mobile)
      } else if (item === 3) { // 已拒绝
        that.getOrderList(2, this.mobile)
      } else if (item === 4) { // 无效单
        that.getOrderList(3, this.mobile)
      }
    },
    // 搜索
    onSearch() {
      if (this.value === '') {
        Toast('请输入手机号')
        return
      } else {
        this.mobile = this.value
        this.getOrderList(9, this.value)
      }
    },
    // 输入框发生变化
    onInput(val) {
      if (val === '') {
        this.mobile = window.localStorage.getItem('mobile') || JSON.parse(localStorage.getItem('userInfo')).mobile
      }
    },
    /**
     * 复制交易号
     * @param item
     */
    copy(item) {
      copyText(this.Transactions, () => {
        Toast('复制成功')
      })
    }
  }
}
</script>

<style lang="less" scoped>
html, body, #app, .content {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
}

.content {
  padding-bottom: 30px;
  background-color: #f8f8f8;
  min-height: 100vh;

  .tips {
    color: #999999;
    font-size: 11px;
    font-weight: 500;
    line-height: 18px;
    padding: 10px;
    text-align: left;

    p {
      font-size: 12px;

      span {
        display: inline-block;
        font-size: 12px;
        transform: scale(0.95);
      }
    }
  }

  .NoData {
    margin-top: 150px;

    .NoDataImg {
      width: 132px;
      height: 115px;
    }

    .NoDataText {
      font-size: 14px;
      color: #666;
      text-align: center;
    }
  }

  .Cards {
    margin: 10px;

    .kon {
      min-height: 30vh;

      img {
        width: 132px;
        height: 115px;
      }

      .w {
        color: #666666;
      }
    }

    .cardsitem {
      padding: 10px;
      border-radius: 7px;
      background-color: #FFFFFF;
      margin-top: 10px;

      .item_title {
        text-align: left;
        font-weight: 500;
        font-size: 15px;
      }

      .item_odd {
        text-align: right;
        display: flex;

        & > span:nth-child(1) {
          color: #666666;
          font-size: 10px;
        }

        p {
          width: 39px;
          height: 18px;
          text-align: center;
          line-height: 18px;
          margin-left: 5px;
          border: 1px solid #666666;
          border-radius: 13px;
          font-size: 12px;
          margin-top: -2px;

          span {
            display: inline-block;
            transform: scale(0.8);
          }
        }
      }

      .customer {
        margin-top: 20px;

        & > div:nth-child(1) {
          text-align: left;
          color: #333333;
        }

        & > div:nth-child(2) {
          text-align: right;
          color: #1F5DEE;
          font-size: 12px;
        }
      }

      .cards_it {
        color: #333333;
        text-align: left;
        margin-top: 10px;
        font-size: 13px;
      }

      .cards_it2 {
        color: #333333;
        margin-top: 10px;
        font-size: 13px;
        display: flex;
        flex-direction: row-reverse;

        .speed {
          width: 6em;
          text-align: right;
          color: #1F5DEE;
          padding: 6px 10px;
          border: 1px solid #1F5DEE;
          border-radius: 13px;
        }
      }
    }

    .overlay {
      background-color: rgba(0, 0, 0, 0.7);
      color: #666666 !important;
      z-index: 9;
    }
  }
}
</style>

<style>
.van-search__content {
  background-color: #fff;
}
</style>
